import React from 'react'
import { useSelector } from 'react-redux'

import { APP_URLS } from '@vega/constants'
import { selectProfileRoles } from '@vega/redux.profile'

import { AppSwitcher as BaseAppSwitcher } from '@vega/components'

function AppSwitcher() {
  const roles = useSelector(selectProfileRoles)
  const onSignOut = () => {
    window.localStorage.removeItem('vegaUserCurrentRole')
    window.location.replace(`${APP_URLS.AUTH}sign-out`)
  }

  return <BaseAppSwitcher onSignOut={onSignOut} usersRoles={roles} />
}

export { AppSwitcher }
