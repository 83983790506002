/* eslint-disable camelcase */
export const FINANCIALS_RETRIEVAL_STATUS = {
  GENERATED: 'generated',
  STARTED: 'started',
  COMPLETED: 'completed',
}

export const APPLICATION_STATUS = {
  SettlementCompleted: 'settlement_completed',
  SettlementBooked: 'settlement_date_booked',
  ConditionallyApproved: 'conditionally_approved',
  UnconditionallyApproved: 'unconditionally_approved',
  AssessorApproved: 'assessor_approved',
  WithPortfolioManager: 'with_portfolio_manager',
  PortfolioManagerApproved: 'portfolio_manager_approved',
  ApplicationReceived: 'application_received',
  WithCreditAssessor: 'with_credit_assessor',
  Cancelled: 'cancelled',
  Declined: 'declined',
  Deferred: 'deferred',
  InProgress: 'in_progress',
  ApplicationResubmitted: 'application_resubmitted',
}

export const APPLICATION_STATUS_DISPLAY_TEXT = {
  application_received: 'Application Received',
  application_resubmitted: 'Application Resubmitted',
  with_credit_assessor: 'With Credit Assessor',
  assessor_approved: 'Assessor Approved',
  with_portfolio_manager: 'With Portfolio Manager',
  portfolio_manager_approved: 'Portfolio Manager Approved',
  conditionally_approved: 'Conditionally Approved',
  unconditionally_approved: 'Unconditionally Approved',
  settlement_date_booked: 'Settlement Date Booked',
  settlement_completed: 'Settlement Completed',
  cancelled: 'Cancelled',
  declined: 'Declined',
  deferred: 'Deferred',
  in_progress: 'In Progress',
}

export const CRR_DISPLAY_TEXT = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
  A4: 'A4',
  A5: 'A5',
  B1: 'B1',
  B2: 'B2',
  B3: 'B3',
  B4: 'B4',
  B5: 'B5',
  C1: 'C1',
  C2: 'C2',
  C3: 'C3',
  C4: 'C4',
  C5: 'C5',
  D1: 'D1',
  D2: 'D2',
  D3: 'D3',
  D4: 'D4',
  D5: 'D5',
  E1: 'E1',
  E2: 'E2',
  E3: 'E3',
  E4: 'E4',
  E5: 'E5',
  X1: 'X1',
  X2: 'X2',
  X3: 'X3',
  X4: 'X4',
  X5: 'X5',
}

export const SEARCH_DISPLAY_TEXT = [
  {
    key: 'name',
    label: 'Applicant Name',
    searchLabel: 'Search applicant name',
  },
  {
    key: 'brokerName',
    label: 'Broker Name',
    searchLabel: 'Search broker name',
  },
  {
    key: 'applicationNumber',
    label: 'Application Number',
    searchLabel: 'Search application number',
  },
  {
    key: 'assignedAssessor',
    label: 'Assigned To',
    searchLabel: 'Search assigned to',
  },
]
